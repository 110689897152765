@font-face {
  font-family: 'Gilroy' !important;
  src: local('Gilroy'), url("../fonts/Gilroy-Regular.ttf");
}

@font-face {
  font-family: 'GilroyMedium';
  src: local('Gilroy'), url("../fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: 'GilroyLight';
  src: local('GilroyLight'), url("../fonts/Gilroy-Light.otf");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: Gilroy, sans-serif;
  
}

.container {
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 125px;
  width: 100% !important;
}



h1,
h2,
h3, a {
  color: #232323;
  font-family: GilroyMedium, sans-serif;
}

button{
  font-family: GilroyMedium !important;
}

p {
  color: #454545;
  font-family: GilroyLight, sans-serif;
}
div, input{
  font-family: GilroyLight, sans-serif;
}

@media screen and (max-width: 1200px) {
  .container {
    padding: 0 100px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    padding: 0 50px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 0 16px;
  }
}